import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: ()=> import('@/views/layout/index'),
    name: 'Layout',
    redirect: '/',
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/index'),
        meta: { title: '首页'}
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/index'),
        meta: { title: '关于知言'}
      },
      {
        path: '/plan',
        name: 'plan',
        component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/index'),
        meta: { title: '解决方案'}
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/index'),
        meta: { title: '联系我们'}
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
