<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    // 监听路由的变化
    $route: {
      handler(to) {
        this.$nextTick(() => {
          let webTitle = to.meta.title || '知言官网'
          document.title = `${webTitle}_知言官网`
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
